import get from 'lodash/get'
import DASHBOARD_FULL_QUERY from '#/graphql/operations/dashboard/dashboardList.gql'
import USER_QUERY from '#/graphql/user/show.gql'
import { FlashMessages } from '@common/singletons'
import HISTOGRAM_WIDGET_SETTINGS_CREATE from '#/graphql/operations/dashboard/widgetSettings/createHistogramWidgetSettings.gql'
import CYCLE_TABLE_WIDGET_SETTINGS_CREATE from '#/graphql/operations/dashboard/widgetSettings/createCycleTableWidgetSettings.gql'
import DASHBOARD_INFORMATION_WIDGET_SETTINGS_CREATE from '#/graphql/operations/dashboard/widgetSettings/createDashboardInformationWidgetSettings.gql'
import PIVOT_TABLE_WIDGET_SETTINGS_CREATE from '#/graphql/operations/dashboard/widgetSettings/createPivotTableWidgetSettings.gql'
import CREATE_DASHBOARD_MUTATION from '#/graphql/operations/dashboard/createDashboard.gql'
import ERROR_STATISTICS_WIDGET_SETTINGS_CREATE from '#/graphql/operations/dashboard/widgetSettings/createErrorStatisticsWidgetSettings.gql'
import ERROR_LOG_WIDGET_SETTINGS_CREATE from '#/graphql/operations/dashboard/widgetSettings/createErrorLogWidgetSettings.gql'
import TABLE_WIDGET_SETTINGS_CREATE from '#/graphql/operations/dashboard/widgetSettings/createTableWidgetSettings.gql'
import DRILLING_TABLE_WIDGET_SETTINGS_CREATE from '#/graphql/operations/dashboard/widgetSettings/createDrillingTableWidgetSettings.gql'

export default {
  data() {
    return {
      // user: null,
      targetUserId: null,
    }
  },
  methods: {
    async copyDashboard(dashboardId, targetUserId, skipUpdateStore = true) {
      this.targetUserId = targetUserId
      if (!targetUserId) {
        throw new Error('Missing userId.')
      }
      const res = await this.$apollo.query({
        query: DASHBOARD_FULL_QUERY,
        variables: {
          id: dashboardId,
        },
      })
      const source = get(res, 'data.dashboard', null)
      if (!source) {
        return
      }
      const target = {
        title: source.title,
        type: source.type,
        ownerUserId: targetUserId,
        dashboardCreatedAt: source.dashboardCreatedAt,
      }
      if (source.picture) {
        target.picture = {
          connect: {
            id: source.picture.id,
          },
        }
      }
      if (source.assets) {
        target.assignedAssets = {
          create: source.assets.map(asset => ({ assetId: asset.id, assetType: asset.__typename })),
        }
      }
      if (source.gridItems.length > 0) {
        let widgetSettingsIds = new Map()
        for (let item of source.gridItems) {
          const widgetSettings = await this.createWidgetSettings(item.widget)
          widgetSettingsIds.set(item.id, widgetSettings)
        }
        target.gridItems = {
          create: source.gridItems.map(gridItem => {
            const dimensions = get(gridItem, 'widget.dimensions', []).map(widgetDimension => {
              return {
                title: widgetDimension.title,
                assetDimension: {
                  connect: {
                    id: widgetDimension.assetDimension.id,
                  },
                },
                color: widgetDimension.color,
                aggregationType: widgetDimension.aggregationType,
                min: widgetDimension.min,
                max: widgetDimension.max,
                axisOnRight: widgetDimension.axisOnRight,
                groupMethod: widgetDimension.groupMethod,
                graphType: widgetDimension.graphType,
                useAssetDimensionThresholds: widgetDimension.useAssetDimensionThresholds,
                targetValue: widgetDimension.targetValue,
                targetValueEnabled: widgetDimension.targetValueEnabled,
                position: widgetDimension.position,
              }
            })
            const widget = gridItem.widget
              ? {
                  create: {
                    type: gridItem.widget.type,
                    title: gridItem.widget.title,
                    useCustomColors: gridItem.widget.useCustomColors,
                    abstractWidgetSettings: {
                      create: {
                        widgetSettingsId: widgetSettingsIds.get(gridItem.id),
                        widgetSettingsType: gridItem.widget.type,
                      },
                    },
                  },
                }
              : null
            if (dimensions && dimensions.length > 0) {
              widget.create.dimensions = {
                create: dimensions,
              }
            }
            return {
              x: gridItem.x,
              y: gridItem.y,
              w: gridItem.w,
              h: gridItem.h,
              widget,
            }
          }),
        }
      }
      await this.$apollo.mutate({
        mutation: CREATE_DASHBOARD_MUTATION,
        variables: {
          data: target,
        },
        update: (store, { data: { createDashboard } }) => {
          if (skipUpdateStore) {
            FlashMessages.$emit('success', `Successfully copied to user`, {
              timeout: 3000,
            })
            return
          }
          const { user } = store.readQuery({
            query: USER_QUERY,
            variables: {
              where: {
                id: targetUserId,
              },
            },
          })
          user.dashboards.push(createDashboard)
          store.writeQuery({
            query: USER_QUERY,
            variables: {
              where: {
                id: targetUserId,
              },
            },
            data: {
              user,
            },
          })
          FlashMessages.$emit('success', `Successfully copied to user`, {
            timeout: 3000,
          })
        },
      })
    },
    async createWidgetSettings(widget) {
      if (!widget) {
        return
      }
      let id = null
      if (widget.type === 'HISTOGRAM') {
        const widgetSettings = { ...widget.widgetSettings }
        delete widgetSettings['id']
        delete widgetSettings['__typename']
        const res = await this.$apollo.mutate({
          mutation: HISTOGRAM_WIDGET_SETTINGS_CREATE,
          variables: {
            data: widgetSettings,
          },
        })
        id = get(res, 'data.createHistogramWidgetSettings.id', null)
      }
      if (widget.type === 'CYCLE_TABLE') {
        const widgetSettings = { ...widget.widgetSettings }
        delete widgetSettings['id']
        delete widgetSettings['__typename']
        const res = await this.$apollo.mutate({
          mutation: CYCLE_TABLE_WIDGET_SETTINGS_CREATE,
          variables: {
            data: widgetSettings,
          },
        })
        id = get(res, 'data.createCycleTableWidgetSettings.id', null)
      }
      if (widget.type === 'DASHBOARD_INFORMATION') {
        const widgetSettings = { ...widget.widgetSettings }
        delete widgetSettings['id']
        delete widgetSettings['__typename']
        const res = await this.$apollo.mutate({
          mutation: DASHBOARD_INFORMATION_WIDGET_SETTINGS_CREATE,
          variables: {
            data: widgetSettings,
          },
        })
        id = get(res, 'data.createDashboardInformationWidgetSettings.id', null)
      }
      if (widget.type === 'PIVOT_TABLE') {
        const widgetSettings = { ...widget.widgetSettings }
        delete widgetSettings['id']
        delete widgetSettings['__typename']
        const res = await this.$apollo.mutate({
          mutation: PIVOT_TABLE_WIDGET_SETTINGS_CREATE,
          variables: {
            data: widgetSettings,
          },
        })
        id = get(res, 'data.createPivotTableWidgetSettings.id', null)
      }
      if (widget.type === 'ERROR_STATISTICS') {
        const widgetSettings = { ...widget.widgetSettings }
        delete widgetSettings['id']
        delete widgetSettings['__typename']
        const mappedSettings = { errorTypeName: { set: widgetSettings.errorTypeName } }
        const res = await this.$apollo.mutate({
          mutation: ERROR_STATISTICS_WIDGET_SETTINGS_CREATE,
          variables: {
            data: mappedSettings,
          },
        })
        id = get(res, 'data.createErrorStatisticsWidgetSettings.id', null)
      }
      if (widget.type === 'ERROR_LOG') {
        const widgetSettings = { ...widget.widgetSettings }
        delete widgetSettings['id']
        delete widgetSettings['__typename']
        const mappedSettings = { errorTypeName: { set: widgetSettings.errorTypeName } }
        const res = await this.$apollo.mutate({
          mutation: ERROR_LOG_WIDGET_SETTINGS_CREATE,
          variables: {
            data: mappedSettings,
          },
        })
        id = get(res, 'data.createErrorLogWidgetSettings.id', null)
      }
      if (widget.type === 'TABLE') {
        const widgetSettings = { ...widget.widgetSettings }
        delete widgetSettings['id']
        delete widgetSettings['__typename']
        const res = await this.$apollo.mutate({
          mutation: TABLE_WIDGET_SETTINGS_CREATE,
          variables: {
            data: widgetSettings,
          },
        })
        id = get(res, 'data.createTableWidgetSettings.id', null)
      }
      if (widget.type === 'DRILLING_TABLE') {
        const widgetSettings = { ...widget.widgetSettings }
        delete widgetSettings['id']
        delete widgetSettings['__typename']
        const res = await this.$apollo.mutate({
          mutation: DRILLING_TABLE_WIDGET_SETTINGS_CREATE,
          variables: {
            data: widgetSettings,
          },
        })
        id = get(res, 'data.createDrillingTableWidgetSettings.id', null)
      }
      return id
    },
  },
}
